/* Switch element */
.form-switch {
    @apply relative select-none;
    width: 44px;
}

.form-switch label {
    @apply block overflow-hidden cursor-pointer h-6 rounded-full;
}

.form-switch label > span:first-child {
    @apply absolute block rounded-full;
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    right: 50%;
    transition: all 0.15s ease-out;
}

.form-switch input[type='checkbox']:checked + label {
    @apply bg-teal-500;
}

.form-switch input[type='checkbox']:checked + label > span:first-child {
    left: 22px;
}
